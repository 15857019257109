/**
 * @name 矩阵
 */
class Matrix {
  /**
   * @name 构造方法
   * @description 行向量表示。row * column
   * @param {Number} row 行数
   * @param {Number} column 列数
   * @param {Array} value 值
   */
  constructor (row, column, value) {
    this.r = row
    this.c = column

    for (let i = 0; i < row; i++) {
      this[i] = []
    }

    if (value) {
      for (let i = 0; i < this.r; i++) {
        for (let j = 0; j < this.c; j++) {
          this[i][j] = value[i][j] ?? this[i][j]
        }
      }
    }
  }

  /**
   * @name 乘-点乘
   * @param other 矩阵
   * @return 结果
   */
  multiplyD (other) {
    const result = new Matrix(this.r, other.c)
    const n = this.c
    for (let i = 0; i < result.r; i++) {
      for (let j = 0; j < result.c; j++) {
        let value = 0
        for (let k = 0; k < n; k++) {
          value += this[i][k] * other[k][j]
        }
        result[i][j] = value
      }
    }

    return result
  }
}

/**
 * @name 生成可移动、缩放的元素
 */
class Atlas {
  /**
   * @name 构造方法
   * @param {String} width 宽度。CSS
   * @param {String} height 高度。CSS
   * @param {Boolean} translate 可移动
   * @param {Boolean} scale 可缩放
   */
  constructor ({ width, height, translate = true, scale = true, translateSpeed = 2, scaleSpeed = 1 } = {}) {
    if (!width) {
      width = '100%'
    }
    if (!height) {
      height = '100%'
    }
    this.$container = null
    this.$content = null

    this.config = {
      translate: true,
      scale: true,
      translateSpeed: 2,
      scaleSpeed: 1
    }
    this.x = 0
    this.y = 0
    this.s = 1
    this.$translate = null
    this.$scale = null
    this.moveDelta = 0

    const $container = document.getElementById('container-box')
    // $container.style.overflow = 'hidden'
    $container.style.position = 'relative'
    $container.style.zIndex = '3'
    $container.style.width = width
    $container.style.height = height
    $container.style.position = 'absolute'
    $container.style.left = '50%'
    $container.style.transform = 'translateX(-50%)'
    $container.addEventListener('mousemove', this.handleMove.bind(this))
    $container.addEventListener('click', this.handleClick.bind(this), true)
    $container.addEventListener('mousewheel', this.handleWheel.bind(this))

    const $translate = document.getElementById('drag-box')
    console.log('$translate', $translate.style)
    $translate.style.transformOrigin = '0 0'

    const $scale = document.getElementById('scale-box')
    $scale.style.transformOrigin = '0 0'

    const $content = document.getElementById('content-box')
    console.log('$content', $content)
    $container.style.width = '100%'
    $content.style.height = '100%'

    // $container.appendChild($translate)
    // $translate.appendChild($scale)
    // $scale.appendChild($content)

    this.$container = $container
    this.$translate = $translate
    this.$scale = $scale
    this.$content = $content
    this.config.translate = translate
    this.config.scale = scale
    this.config.translateSpeed = translateSpeed
    this.config.scaleSpeed = scaleSpeed
  }

  /**
   * @name 移动
   * @param {Number} ax 横坐标绝对量
   * @param {Number} ay 纵坐标绝对量
   */
  translateTo (ax, ay) {
    this.x = ax ?? this.x
    this.y = ay ?? this.y

    this.translate()
  }

  /**
   * @name 移动
   * @param {Number} dx 横坐标偏移量
   * @param {Number} dy 纵坐标偏移量
   */
  translateBy (dx, dy) {
    this.x += dx ?? 0
    this.y += dy ?? 0

    this.translate()
  }

  /**
   * @name 缩放
   * @param {Number} as 系数绝对量
   */
  scaleTo (as) {
    this.s = as ?? this.s

    this.scale()
  }

  /**
   * @name 缩放
   * @param {Number} ds 系数偏移量
   */
  scaleTods (ds) {
    this.s += ds ?? 0
    this.scale()
  }

  /**
   * @name 处理鼠标拖动
   * @param {Object} ev 事件对象
   */
  handleMove (ev) {
    if (this.config.translate) {
      if (ev.buttons === 1) {
        this.x += (ev.movementX / this.s) * this.config.translateSpeed
        this.y += (ev.movementY / this.s) * this.config.translateSpeed

        this.moveDelta += Math.abs(ev.movementX + ev.movementY)

        this.translate()
      }
    }
  }

  /**
   * @name 处理鼠标抬起
   * @description 阻止拖动时点击
   * @param {Object} ev 事件对象
   */
  handleClick (ev) {
    if (this.moveDelta > 10) {
      ev.preventDefault()
      ev.stopPropagation()
    }

    this.moveDelta = 0
  }

  /**
   * @name 处理鼠标滚轮
   * @param {Object} ev 事件对象
   */
  handleWheel (ev) {
    if (this.config.scale) {
      ev.preventDefault()
      console.log('ev', ev)
      const delta = -(ev.deltaY / 2000) * this.config.scaleSpeed
      this.s *= 1 + delta
      if (this.s >= 0.5 && this.s <= 2) {
        this.origin(delta, ev.clientX - 390, ev.clientY - 165)
        this.scale()
      }
      if (this.s < 0.5) {
        this.s = 0.5
      }
      if (this.s > 2) {
        this.s = 2
      }
    }
  }

  /**
   * @name 平移
   */
  translate () {
    this.$translate.style.transform = `translate(${this.x}px, ${this.y}px)`
  }

  /**
   * @name 缩放原点
   * @param {Number} delta 缩放系数变化量
   * @param {Number} ox 缩放中心横坐标
   * @param {Number} oy 缩放中心纵坐标
   */
  origin (delta, ox, oy) {
    const v = new Matrix(1, 3, [[this.x, this.y, 1]])
    const tf = new Matrix(3, 3, [
      [1, 0, 0],
      [0, 1, 0],
      [-ox, -oy, 1]
    ])
    const sc = new Matrix(3, 3, [
      [1 + delta, 0, 0],
      [0, 1 + delta, 0],
      [0, 0, 1]
    ])
    const tb = new Matrix(3, 3, [
      [1, 0, 0],
      [0, 1, 0],
      [ox, oy, 1]
    ])
    const r = v.multiplyD(tf).multiplyD(sc).multiplyD(tb)

    this.x = r[0][0]
    this.y = r[0][1]
    console.log('this.x', this.x)
    console.log('this.y', this.y)
    this.translate()
  }

  /**
   * @name 缩放
   */
  scale () {
    this.$scale.style.transform = `scale(${this.s})`
  }
}

export default Atlas
