<template>
  <div class="store-earlywarning" :style="!hasRightShow?'padding:10px 64px':''">
    <div class="w-crumbs-href">
      <span>您现在所在的位置：</span>
      <router-link
        tag="span"
        :to="{ path: '/dataSmartCockpit' }"
      >数智驾舱</router-link>
      <i class="el-icon-arrow-right"></i>
      <span>店铺预警分析</span>
    </div>
    <div v-if="hasRightShow" class="wrap-box">
      <div class="sidebar fl">
        <div class="shadow-box project-info">
          <img v-if="projectInfo.projectLogo" :src="projectInfo.projectLogo" width="106" height="80" alt="">
          <div class="w-input-sty project-select">
            <el-select
              ref="projectSelector"
              class="input-solid"
              popper-class="w-block-select-down yellow-select-panel"
              v-model="filtro.project"
              filterable
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in projectOptions"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <ul class="data-list ellipsis">
            <li>
              在营店铺
              <span class="data-span">{{projectData.openStoreNum | nonEmpty}}</span>
            </li>
            <li>
              经营异常
              <span class="data-span">{{projectData.todayWaningNum | nonEmpty}}</span>
              <span
                v-if="Number(projectData.compareYesterday)"
                :class="Number(projectData.compareYesterday)>0?'c-icon-up':'c-icon-down'">
              </span>
              <span
                v-if="Number(projectData.compareYesterday)"
                :class="Number(projectData.compareYesterday)>0?'cls-highlight':'cls-down'"
                class="trend-span">{{projectData.compareYesterday | nonEmpty}}
              </span>
            </li>
          </ul>
        </div>
        <div class="shadow-box store-monitoring mt-14">
          <h2 class="w-title-gradients">店铺监控预警概览</h2>
          <div class="tbs-list">
            <h3>
              <span>监控指标</span>
              <span>触发预警店铺数量</span>
            </h3>
            <ul class="w-singed-table">
              <li v-for="(item,i) in monitoringList" :key="i">
                <span>{{item.measureName}}</span>
                <span class="num">{{item.warningStoreNum}}</span>
              </li>
              <li v-if="monitoringList.length===0 && isSetDataView" class="low-item">
                <span>-</span>
                <span>-</span>
              </li>
              <br v-else>
            </ul>
          </div>
        </div>
        <div class="shadow-box store-monitoring mt-14">
          <h2 class="w-title-gradients">重点监控店铺</h2>
          <div class="tbs-list">
            <h3>
              <span>店铺名称</span>
              <span>触发预警项</span>
            </h3>
            <ul class="w-singed-table">
              <li
                v-for="(item,i) in monitoringStoreList"
                :key="i"
                @click="goDetailsPage(item)"
                class="pointer"
                >
                <span>{{item.storeName}}</span>
                <span class="num">{{item.warningNum}}</span>
                <i class="icon-page-enter"></i>
              </li>
              <li v-if="monitoringStoreList.length===0 && isSetDataView" class="low-item">
                <span>-</span>
                <span>-</span>
              </li>
              <br v-else>
            </ul>
          </div>
        </div>
      </div>
      <div class="main-panel">
        <el-autocomplete
          class="w-input-sty store-input input-solid"
          v-model="inputStore"
          prefix-icon="el-icon-search"
          popper-class="yellow-select-panel"
          :fetch-suggestions="querySearch"
          placeholder="请输入店铺名称"
          @input="searchStore"
          @select="goDetailsPage"
        ></el-autocomplete>
        <div class="bg-light"></div>
        <div class="bg-light-move-left"></div>
        <div class="bg-light-move-right"></div>
        <div class="bg-light-move-tright"></div>
        <div class="bg-light-move-tleft"></div>
        <div class="bubble-wrap">
          <div ref="container" id="container-box">
            <div ref="dragBox" id="drag-box">
              <div ref="scaleBox" id="scale-box">
                <div id="content-box">
                  <ul class="bubble-list" id="bubble-list" ref="bubbleList">
                    <li
                      class="bubble-item pointer"
                      :class="[
                        bubbleSizeList[item.size],
                        'float-type-'+item.type
                      ]"
                      v-for="item in bubbleList"
                      :key="item.storeCode"
                      @click="goDetailsPage(item)"
                    >
                      <span class="ellipsis">{{item.storeName}}</span>
                      <ul class="text-panel" @click.stop="">
                        <h3>{{item.storeName}}</h3>
                        <h4 v-if="item.textList0 && item.textList0.length">经营监控</h4>
                        <li v-for="ele in item.textList0" :key="ele.measureCode">
                          {{ele.ruleConditionDesc}}
                          <span class="fr cls-highlight">{{ele.measureValue + ele.measureUnit}}</span>
                        </li>
                        <p v-if="item.textList0 && item.textList0.length && item.textList1 && item.textList1.length" style="padding:0 8px;height: 1px;background: #373a43;margin:5px 0"></p>
                        <h4 v-if="item.textList1 && item.textList1.length">费用监控</h4>
                        <li v-for="ele in item.textList1" :key="ele.measureCode">
                          {{ele.ruleConditionDesc}}
                          <span class="fr cls-highlight">{{ele.measureValue + ele.measureUnit}}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <ul class="effects-light-list">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div
          ref="floorsHandler"
          class="building-floors-handler">
          <el-select
            class="input-solid"
            popper-class="w-block-select-down yellow-select-panel"
            v-model="buildingCode"
            size="small"
            @change="getDataGdFloors"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in buildingList"
              :key="item.buildingCode"
              :label="item.buildingName"
              :value="item.buildingCode"
            >
            </el-option>
          </el-select>
          <ul class="floor-list" ref="floorList">
            <li
              :class="limitNum !== 0?'pointer':'disabled-item'"
              v-if="floorList.length > 5"
              @click="handleFloorLimit(0)"
            >
              <i class="icon-page-return"></i>
            </li>
            <li
              class="pointer"
              v-for="item in floorListLimit"
              :key="item.buildingName"
              :class="{'active-item': floorCode === item.buildingCode}"
              @click="changeFloor(item.buildingCode)"
            >{{item.buildingName}}</li>
            <li
              :class="limitNum !== floorList.length - 5?'pointer':'disabled-item'"
              v-if="floorList.length > 5"
              @click="handleFloorLimit(1)">
              <i class="icon-page-enter"></i>
            </li>
          </ul>
        </div>
        <div class="earlywarning-setting" ref="settingPanel">
          <div
            class="btn-setting"
            :class="[{'btn-setting-back':settingShow}, btnDisabled!==2?'pointer-evnets-none':'pointer-evnets-auto']"
            ref="settingBtn"
            v-if="hasRightSet"
            @click="showSettingPanel(!settingShow)"
          >
            预警<br>
            设置
            <i :class="settingShow?'icon-page-enter':'icon-page-return'"></i>
          </div>
          <ul class="tabs-header">
            <li
              :class="{'active-item': tabsIndex===0}"
              @click="handleTabs(0)">经营监控</li>
            <li
              :class="{'active-item': tabsIndex===1}"
              @click="handleTabs(1)">费用监控</li>
          </ul>
          <div class="tabs-body">
            <div class="sub-tabs">
              预警规则
              <el-tooltip
                class="item"
                effect="dark"
                content="当前项目仅支持生效一种预警规则"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
              <ul class="radio-group">
                <li
                  :class="{'active-item': settingRadio===i}"
                  v-for="(item, i) in settingRadioList"
                  :key="i"
                  @click="handleRadio(i)">{{item}}</li>
              </ul>
            </div>
            <div class="earlywarning-list w-singed-table">
              <table>
                <tr>
                  <th width="14%">监控指标</th>
                  <th width="20%">应用范围</th>
                  <th width="">触发预警条件</th>
                  <th width="9%">是否启用</th>
                  <th width="9%" v-if="settingRadio">添加/删除</th>
                </tr>
                <!-- !(item.isAdd===1 && settingRadio===0) 预警规则：新增条目在统一设置radio时隐藏 -->
                <tr
                  v-show="tabsIndex===0 && !(item.isAdd===1 && settingRadio===0)"
                  v-for="(item,index) in earlywarningManageList"
                  :key="item.id">
                  <td>{{item.isAdd?'':item.measureName}}</td>
                  <td v-if="settingRadio===0">统一</td>
                  <td v-else-if="settingRadio===1">
                    <div class="input-box">
                      <el-cascader
                        :props="{multiple: true}"
                        popper-class="yellow-select-panel"
                        :options="item.industryFloorApplyRanges"
                        v-model="item.ranges1"
                        collapse-tags
                        @change="changeArea(item)"
                        @visible-change="handleArea($event, item)"
                        clearable></el-cascader>
                    </div>
                  </td>
                  <td v-else-if="settingRadio===2">
                    <div class="input-box">
                      <el-cascader
                        :props="{multiple: true}"
                        popper-class="yellow-select-panel"
                        :options="item.floorIndustryApplyRanges"
                        v-model="item.ranges2"
                        collapse-tags
                        @change="changeArea(item)"
                        @visible-change="handleArea($event, item)"
                        clearable></el-cascader>
                    </div>
                  </td>
                  <td>
                    <div class="input-box" :class="{'mr-16 more-margin': item.measureName === '上月租售比'}">
                      <el-select
                        class="xl-select"
                        popper-class="w-block-select-down"
                        v-model="item.condition"
                        @change="isChange=true"
                        clearable>
                        <el-option
                          v-for="ele in item.allConditions"
                          :key="ele.value"
                          :label="ele.name"
                          :value="ele.value">
                        </el-option>
                      </el-select>
                      <el-input
                        class="value-item"
                        v-model="item.warningValue"
                        @blur="conditionRule(0, item)"
                        :onkeyup="handleInput(item.warningValue)"
                        :onafterpaste="handleInput(item.warningValue)"
                        @change="isChange=true"
                        ></el-input>
                      <span class="unit">{{item.measureUnit}}</span>
                    </div>
                    <div class="input-box more-margin" style="margin-top:0px" v-if="item.measureName === '上月租售比'">
                      <el-select
                        class="xl-select"
                        popper-class="w-block-select-down"
                        v-model="item.orCondition"
                        clearable
                        @change="isChange=true"
                        >
                        <el-option
                          v-for="ele in item.orAllConditions"
                          :key="ele.value"
                          :label="ele.name"
                          :value="ele.value">
                        </el-option>
                      </el-select>
                      <el-input
                        class="value-item"
                        v-model="item.orWarningValue"
                        @blur="conditionRule(1, item)"
                        :onkeyup="handleInput(item.orWarningValue)"
                        :onafterpaste="handleInput(item.orWarningValue)"
                        @change="isChange=true"
                        ></el-input>
                      <span class="unit">{{item.measureUnit}}</span>
                    </div>
                  </td>
                  <td>
                    <span
                      class="c-switch"
                      :class="{'active': item.status === 1}"
                      @click="handleSwitch(index)"
                      ></span>
                  </td>
                  <td v-if="settingRadio">
                    <span v-if="!item.isAdd" class="ico-add" @click="addItem(index, item)"></span>
                    <span v-if="item.isAdd===1" class="ico-del" @click="delItem(index, item)"></span>
                  </td>
                </tr>
                <tr
                  v-show="tabsIndex===1 && !(item.isAdd===1 && settingRadio===0)"
                  v-for="(item,index) in earlywarningCostList"
                  :key="item.id">
                  <td>{{item.isAdd?'':item.measureName}}</td>
                  <td v-if="settingRadio===0">统一</td>
                  <td v-else-if="settingRadio===1">
                    <div class="input-box">
                      <el-cascader
                        :props="{multiple: true}"
                        popper-class="yellow-select-panel"
                        :options="item.industryFloorApplyRanges"
                        v-model="item.ranges1"
                        collapse-tags
                        @change="changeArea(item)"
                        @visible-change="handleArea($event, item)"
                        clearable></el-cascader>
                    </div>
                  </td>
                  <td v-else-if="settingRadio===2">
                    <div class="input-box">
                      <el-cascader
                        :props="{multiple: true}"
                        popper-class="yellow-select-panel"
                        :options="item.floorIndustryApplyRanges"
                        v-model="item.ranges2"
                        collapse-tags
                        @change="changeArea(item)"
                        @visible-change="handleArea($event, item)"
                        clearable></el-cascader>
                    </div>
                  </td>
                  <td>
                    <div class="input-box mr-16">
                      <el-select
                        class="xl-select"
                        popper-class="w-block-select-down"
                        v-model="item.condition"
                        clearable>
                        <el-option
                          v-for="ele in item.allConditions"
                          :key="ele.value"
                          :label="ele.name"
                          :value="ele.value">
                        </el-option>
                      </el-select>
                      <el-input
                        class="value-item"
                        v-model="item.warningValue"
                        @blur="conditionRule(0, item)"
                        :onkeyup="handleInput(item.warningValue)"
                        :onafterpaste="handleInput(item.warningValue)"
                      ></el-input>
                      {{item.measureUnit}}
                    </div>
                    <!-- <div class="input-box" v-if="item.measureName === '上月租售比'">
                      <el-select
                        popper-class="w-block-select-down"
                        v-model="item.orCondition"
                        clearable>
                        <el-option
                          v-for="ele in item.allConditions"
                          :key="ele.value"
                          :label="ele.name"
                          :value="ele.value">
                        </el-option>
                      </el-select>
                      <el-input
                        @blur="conditionRule($event, item)"
                        v-model="item.orWarningValue"
                        onkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                        onafterpaste="this.value=this.value.replace(/[^\d]/g,'')"></el-input>
                      {{item.measureUnit}}
                    </div> -->
                  </td>
                  <td>
                    <span
                      class="c-switch"
                      :class="{'active': item.status === 1}"
                      @click="handleSwitch(index)"
                      ></span>
                  </td>
                  <td v-if="settingRadio">
                    <span v-if="!item.isAdd" class="ico-add" @click="addItem(index, item)"></span>
                    <span v-if="item.isAdd===1" class="ico-del" @click="delItem(index, item)"></span>
                  </td>
                </tr>
              </table>
              <div class="submit-panel">
                <span class="button-basic button-primary" @click="submitSetting">保存</span>
                <span class="button-basic button-low" @click="showSettingPanel(0)">取消</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Chart from '@/utils/canvasBubble.js'
import { mapGetters } from 'vuex'
export default {
  name: 'storeEarlywarning',
  data () {
    return {
      filtro: {
        project: 0
      },
      buildingCode: '',
      buildingList: '',
      floorList: [],
      floorListLimit: [],
      limitNum: 0,
      floorCode: '',
      projectOptions: [],
      projectInfo: {},
      projectData: {},
      monitoringList: [],
      monitoringStoreList: [],
      inputStore: '',
      storeList: [],
      settingShow: false,
      tabsIndex: 0,
      settingRadio: 0,
      settingRadioManage: 0,
      settingRadioCost: 0,
      settingRadioList: ['统一设置', '按业态-楼层设置', '按楼层-业态设置'],
      earlywarningManageList: [],
      earlywarningCostList: [],
      isChange: false,
      bubbleList: [],
      bubbleSizeList: ['size-s', 'size-m', 'size-l'],
      loading: false,
      checkedArea: [],
      optionManage: {},
      isSetDataView: false,
      btnDisabled: 0,
      optionCost: {},
      hasRightShow: false,
      hasRightSet: false
    }
  },
  components: {
  },
  mounted () {
    const userAuthorities = this.user.confIds.find(item => {
      return item.id === 172
    })
    if (userAuthorities) {
      if (userAuthorities.func.indexOf('show') !== -1) {
        this.hasRightShow = true
      }
      if (userAuthorities.func.indexOf('setwarning') !== -1) {
        this.hasRightSet = true
      }
    } else {
      this.hasRightSet = false
      this.hasRightShow = false
    }
    this.getDataProjList()
    const _this = this
    this.filtro = new Proxy({}, {
      set: function (target, propKey, value, receiver) {
        console.log('target', target)
        console.log('propKey', propKey)
        console.log('value', value)
        console.log('_this.filtro.project', _this.filtro.project)
        console.log('receiver', receiver)
        if (value !== _this.filtro.project) {
          if (_this.settingShow) {
            _this.$confirm('当前正在打开预警设置面板，是否切换项目？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              showClose: false,
              customClass: 'message-box'
            }).then(() => {
              Reflect.set(target, propKey, value)
              _this.isChange = false
              _this.inputStore = ''
              _this.buildingCode = ''
              _this.floorCode = ''
              _this.limitNum = 0
              _this.$refs.projectSelector.blur()
              _this.showSettingPanel(0)
              _this.storeList.splice(0)
              _this.updateProjectIdFunc()
              _this.searchStore() // 默认请求在营店铺接口
              _this.getDataViewData()
              _this.getDataGdBuilding()
              _this.getDataGdFloors()
            }).catch(() => {
              _this.$refs.projectSelector.blur()
              console.log('2')
            })
          } else {
            Reflect.set(target, propKey, value)
            _this.changeProject()
          }
        }
        return true
      }
    })
  },
  filters: {
    nonEmpty (num) {
      let res = ''
      if (num === 0 || num) {
        res = Math.abs(num)
      } else {
        res = '-'
      }
      return res
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    setCanvasBox () {
      const dragBox = this.$refs.dragBox
      const translationBox = this.$refs.translationBox
      this.$refs.dragBox.style.transform = ''
      this.$refs.translationBox.style.transform = ''
      console.log('dragBox', dragBox)
      console.log('translationBox', translationBox)
    },
    handleRadio (i) {
      this.checkedArea = {}
      this.settingRadio = i
      if (this.tabsIndex === 1) {
        this.earlywarningCostList = this.earlywarningCostList.filter(item => {
          item.ranges1 = []
          item.ranges2 = []
          item.condition = ''
          item.orCondition = ''
          item.warningValue = ''
          item.orWarningValue = ''
          return item.isAdd !== 1
        })
      } else {
        this.earlywarningManageList = this.earlywarningManageList.filter(item => {
          item.ranges1 = []
          item.ranges2 = []
          item.condition = ''
          item.orCondition = ''
          item.warningValue = ''
          item.orWarningValue = ''
          return item.isAdd !== 1
        })
      }
    },
    handleInput (val) {
      if (val) {
        val = val.toString().match(/^\d+(?:\.\d{0,2})?/)
      }
    },
    conditionRule (key, item) {
      let value = ''
      if (key) {
        value = 'orWarningValue'
      } else {
        value = 'warningValue'
      }
      if (isNaN(item[value])) {
        item[value] = ''
        this.$message({
          type: 'warning',
          message: '请输入数字'
        })
        return
      }
      const trim = (str) => {
        console.log('str', str)
        if (str) {
          return str.toString().replace(/(^\s*)|(\s*$)/g, '')
        }
      }
      item[value] = trim(item[value])
      const hasDot = (num) => {
        if (!isNaN(num)) {
          if (num.toString().indexOf('.') !== -1) {
            console.log('num', num)
            const decimalsLen = num.split('.')[1].length
            console.log('decimalsLen', decimalsLen)
            if (item.measureCode === 'HTDQSC' || item.measureCode === 'QFZQ') {
              if (decimalsLen > 0) {
                this.$message({
                  type: 'warning',
                  message: '该项不允许输入小数'
                })
              }
            } else {
              if (decimalsLen > 2) {
                this.$message({
                  type: 'warning',
                  message: '取值范围小数位为2位'
                })
              }
            }
            return [true, Number(num).toFixed(2)]
          } else {
            return [false, num]
          }
        } else {
          return [false, '']
        }
      }
      const ruleValue = (val, len) => {
        if (!len) {
          len = 5
        }
        const [flag, value] = hasDot(val) // flag 指示是否含有小数点；value保留两位小数格式化以后的值
        let res = value
        let isOver = false
        let integerNum = 0
        let fractionalNum = 0
        integerNum = value.toString().split('.')[0]
        flag ? fractionalNum = value.toString().split('.')[1] : fractionalNum = 0
        console.log('integerNum.length', integerNum.length)
        if (integerNum.length > len) {
          if (flag) {
            res = integerNum.toString().substring(0, len) + '.' + fractionalNum
          } else {
            res = integerNum.toString().substring(0, len)
          }
          isOver = true
        }
        if (isOver) {
          if (item.measureCode === 'XSEYHB' || item.measureCode === 'ZJYHB') {
            len = 5
          }
          this.$message({
            type: 'warning',
            message: '取值范围为整数位' + len + '位，小数位2位'
          })
        }
        return res
      }
      if (item[value] < 0) {
        if (item.measureCode === 'XSEYHB' || item.measureCode === 'ZJYHB') { // 销售额月环比 ||
        } else {
          this.$message({
            type: 'warning',
            message: '请输入正整数'
          })
          item[value] = Math.abs(item[value])
        }
      }
      if (item.measureCode === 'SYZSB') { // 上月租售比
        item[value] = ruleValue(item[value])
        if (item.warningValue !== '') {
          if (Number(item.orWarningValue) > Number(item.warningValue)) {
            this.$message({
              type: 'warning',
              message: '上月租售比预警值不能形成区间'
            })
            item[value] = ''
          }
        }
      } else if (item.measureCode === 'SYFYSJL' || item.measureCode === 'SYXSGXD') { // 上月费用收缴率 || 上月销售贡献度
        item.warningValue = ruleValue(item.warningValue)
      } else if (item.measureCode === 'SYXSPX') { // 上月销售坪效
        item.warningValue = ruleValue(item.warningValue, 6)
      } else if (item.measureCode === 'LJQFJE') { // 累计欠费金额
        item.warningValue = ruleValue(item.warningValue, 8)
      } else if (item.measureCode === 'HTDQSC' || item.measureCode === 'QFZQ') { // 合同到期时长 || 欠费账期
        const [flag, value] = hasDot(item.warningValue)
        if (flag) {
          item.warningValue = value.toString().split('.')[0]
        }
        if (item.warningValue.length > 5) {
          this.$message({
            type: 'warning',
            message: '取值范围为整数位5位，不允许输入小数'
          })
          item.warningValue = item.warningValue.toString().substring(0, 5)
        }
      } else if (item.measureCode === 'XSEYHB' || item.measureCode === 'ZJYHB') { // 销售额月环比 ||
        if (item.warningValue > 0) {
          item.warningValue = ruleValue(item.warningValue)
        } else {
          item.warningValue = ruleValue(item.warningValue, 6)
        }
      }
    },
    id2Arr (idArr, arr) {
      return idArr && idArr.map(id => {
        let curr = id
        arr && arr.forEach(item => item.id === id && (curr = item))
        return curr
      })
    },
    arr2Id (arr) {
      return arr && arr.map(item => item.id ? item.id : item)
    },
    complement (arr1, arr2) {
      const mergeArr = arr1 && arr2 && arr1.concat(arr2)
      arr1 = this.arr2Id(arr1)
      arr2 = this.arr2Id(arr2)
      const idArr = arr1.filter(item => !arr2.includes(item))
      return this.id2Arr(idArr, mergeArr)
    },
    changeArea (item) {
      const measureCode = item.measureCode
      this.isChange = true
      this.checkedArea[measureCode] = []
      // 从整个列表里面获取该规则的列表
      let dataSource = null // 指向不同的数组（数据）
      if (this.tabsIndex === 1) {
        dataSource = this.earlywarningCostList
      } else {
        dataSource = this.earlywarningManageList
      }
      const list = dataSource.filter(item => {
        return item.measureCode === measureCode
      })
      // 将所有选中的项保存起来
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (this.settingRadio === 1) {
          this.checkedArea[measureCode].push(...item.ranges1)
        } else if (this.settingRadio === 2) {
          this.checkedArea[measureCode].push(...item.ranges2)
        }
      }
      this.setDisabledOptions(item)
    },
    setDisabledOptions (item) {
      const measureCode = item.measureCode
      let dataSource = null
      let ranges = ''
      let options = ''
      if (this.settingRadio === 1) {
        dataSource = item.industryFloorApplyRanges
        ranges = 'ranges1'
        options = 'industryFloorApplyRanges'
      } else {
        dataSource = item.floorIndustryApplyRanges
        ranges = 'ranges2'
        options = 'floorIndustryApplyRanges'
      }
      // this.checkedArea[measureCode] 所有选中的
      // ranges 当前选中的
      // list 对比当前选中的所有，排除当前item已选中的，剩下的就是其他已选中的，禁选它们
      let list = this.complement(this.checkedArea[measureCode], item[ranges])
      list = list.sort((x, y) => { // 用户选择可能乱序，排序方便处理
        return x[0] - y[0]
      })
      console.log('该项所有选中', this.checkedArea[measureCode])
      console.log('该项排除当前条目已选中', list)
      console.log('item', item)
      console.log('ranges', item[ranges])
      const objList = [] // 将排除当前已选取列表处理成对象更容易处理
      let tempValue = '' // 记录一级的值
      let tempObj = {}
      for (let i = 0; i < list.length; i++) {
        const element = list[i]
        if (tempValue === '') { // 初始化
          tempValue = element[0]
          tempObj.value = element[0]
          tempObj.list = [element[1]]
          if (list.length === 1) { // 处理只有一条的情况
            objList.push(tempObj)
          }
        } else {
          if (element[0] === tempValue) { // 同一级
            tempObj.list.push(element[1])
            if (i === list.length - 1) { // 处理最后一条
              objList.push(tempObj)
            }
          } else { // 下一级
            objList.push(tempObj)
            tempObj = {}
            tempValue = element[0]
            tempObj.value = element[0]
            tempObj.list = [element[1]]
            if (i === list.length - 1) { // 处理下一条为最后一条的情况
              objList.push(tempObj)
            }
          }
        }
      }
      console.log('objList', objList)
      if (objList && objList.length === 0) { // 如果不存在需要禁选的项，那么全设为可选，解决清空选择不进入判断的问题
        for (let j = 0; j < dataSource.length; j++) {
          const item0 = dataSource[j]
          item0.disabled = false
          for (let k = 0; k < item0.children.length; k++) {
            const item1 = item0.children[k]
            item1.disabled = false
          }
        }
      } else { // 禁用排除当前的已选取的项
        for (let i = 0; i < objList.length; i++) {
          const element = objList[i]
          for (let j = 0; j < dataSource.length; j++) {
            const item0 = dataSource[j]
            if (element.list.length === item0.children.length) {
              item0.disabled = true
            } else {
              item0.disabled = false
            }
            if (item0.value === element.value) {
              for (let k = 0; k < item0.children.length; k++) {
                const item1 = item0.children[k]
                if (element.list.indexOf(item1.value) !== -1) {
                  item1.disabled = true
                } else {
                  item1.disabled = false
                }
              }
            }
          }
        }
        for (let i = 0; i < dataSource.length; i++) { // 禁用一级
          const item0 = dataSource[i]
          let count = 0
          for (let j = 0; j < item0.children.length; j++) {
            const item1 = item0.children[j]
            if (item1.disabled) {
              count++
            }
          }
          if (count === item0.children.length) {
            item0.disabled = true
          } else {
            item0.disabled = false
          }
        }
        for (let i = 0; i < item[ranges].length; i++) { // 解禁当前item已选
          const element = item[ranges][i]
          for (let j = 0; j < dataSource.length; j++) {
            const item0 = dataSource[j] // 目标item option一级
            if (item0.value === element[0]) {
              let count = 0
              for (let k = 0; k < item0.children.length; k++) {
                const item1 = item0.children[k]
                if (item1.value === element[1]) {
                  item1.disabled = false
                  count++
                }
                if (count) {
                  item0.disabled = false
                }
              }
            }
          }
        }
      }
      if (item[options]) { // 第一级禁选
        if (item[ranges] && item[ranges].length) {
          for (let i = 0; i < item[options].length; i++) {
            const element = item[options][i]
            if (Number(element.value) === Number(item[ranges][0][0])) {
              element.disabled = false
            } else {
              element.disabled = true
            }
          }
        } else {
          for (let i = 0; i < item[options].length; i++) {
            const element = item[options][i]
            if (element.disabled !== true) {
              element.disabled = false
            }
          }
        }
      }
      item[options] = item[options].map(item => {
        return item
      })
    },
    handleArea (key, item) {
      // this.checkedArea 存储已选中的项，以measureCode作为对象属性
      const measureCode = item.measureCode
      if (key) {
        if (!(measureCode in this.checkedArea)) { // 如果未设置该项的属性，那么设置它
          this.changeArea(item)
        }
        this.setDisabledOptions(item)
      }
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.filtro.project
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    goDetailsPage (item) {
      const path = this.$router.resolve({
        path: '/earlywarningAnalysis/basicData',
        query: {
          projectId: this.filtro.project,
          storeCode: item.storeCode
        }
      })
      window.open(path.href, '_blank')
    },
    querySearch (queryString, cb) {
      const memberList = this.storeList
      const results = memberList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    searchStore (storeName) {
      const params = {
        projectId: this.filtro.project,
        storeName: storeName
      }
      this.axios.get(api.searchStore, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.storeList = resData.map(item => {
            item.value = item.storeName
            return item
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    sumNum (arr) {
      let res = 0
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index]
        res += element
      }
      return res
    },
    unique (arr) {
      var arr1 = [] // 新建一个数组来存放arr中的值
      for (var i = 0, len = arr.length; i < len; i++) {
        if (arr1.indexOf(arr[i]) === -1) {
          arr1.push(arr[i])
        }
      }
      return arr1
    },
    submitSetting () {
      this.btnDisabled = 0
      let dataSource = '' // 经营监控、费用监控 前端保留两套数据，用这个来指向不同的数据
      if (this.tabsIndex === 0) {
        dataSource = this.earlywarningManageList
      } else {
        dataSource = this.earlywarningCostList
      }
      // emptyList 储存留空的情况 0 应用范围 1 触发条件符号 2触发条件数值 (上月租售比：3 触发条件符号2 4触发条件数值2)
      let emptyList = []
      // areaList msg输出留空的位置
      const areaList = ['应用范围', '触发预警条件', '触发预警值', '触发预警条件', '触发预警值']
      // fullNum 指示当前情况下满选的条数：0.统一设置一般满选2，上月租售比满选4；1、2 满选3，上月租售比满选5
      let fullNum = 0
      const params = {
        appliedRange: this.settingRadio + 1, // appliedRange (integer, optional): 应用范围：1-统一设置，2-按楼层-业态，3-按业态-楼层 ,
        detailRule: [ // (Array[规则详情], optional): 规则详情 ,
          {
            measureCode: '', // (string, optional): 指标类型编号 ,
            leftCode: [ // (Array[string], optional): 应用范围为2时：楼层编号，应用范围为3时：一级业态编号，逗号分隔 ,
              ''
            ],
            rightCode: [ // (Array[string], optional): 应用范围为2时：一级业态编号，应用范围为3时：楼层编号，逗号分隔 ,
              ''
            ],
            condition: '', // (string, optional): 条件表达式：<,>,<=,>=等 ,
            orCondition: '', // (string, optional): 或者，条件表达式：<,>,<=,>=等 ,
            warningValue: 0, // (number, optional): 预警值/预警起始值，单值验证存在此值内，默认为0.00
            orWarningValue: 0, // (number, optional): 或者，预警值 ,
            status: 0 // (integer, optional): 是否启用：1-启用，2-禁用 ,
          }
        ],
        projectId: this.filtro.project // projectId (integer, optional): 项目ID
      }
      if (params.appliedRange === 2) { // 高德唯斯的接口到上线前才暴露他们初始的设置错误，这里需要反转来处理
        params.appliedRange = 3
      } else if (params.appliedRange === 3) {
        params.appliedRange = 2
      }
      params.detailRule = []
      let ranges = ''
      if (this.settingRadio === 1) {
        ranges = 'ranges1'
      } else {
        ranges = 'ranges2'
      }
      if (this.settingRadio === 0) {
        dataSource = dataSource.filter((item) => {
          return !item.isAdd
        })
      }
      for (let i = 0; i < dataSource.length; i++) {
        const item = dataSource[i]
        console.log('ranges', ranges)
        console.log('item[ranges]', item[ranges])
        emptyList = []
        const tempObj = {}
        tempObj.measureCode = item.measureCode // 指标类型编号
        tempObj.status = item.status
        // 1.应用范围
        tempObj.leftCode = []
        tempObj.rightCode = []
        if (this.settingRadio !== 0) { // 预警规则不是统一设置
          fullNum = 3
          if (item.measureName === '上月租售比') {
            fullNum = 5
          }
          if (item[ranges] && item[ranges].length !== 0) { // 有选择应用范围
            for (let j = 0; j < item[ranges].length; j++) {
              const element = item[ranges][j]
              tempObj.leftCode.push(element[0])
              tempObj.rightCode.push(element[1])
              // tempObj.leftCode = this.unique(tempObj.leftCode) // 应用范围
              // tempObj.rightCode = this.unique(tempObj.rightCode) // 应用范围
            }
            // const objList = []
            // let tempValue = '' // 记录一级的值
            // let tObj = {}
            // for (let i = 0; i < item[ranges].length; i++) {
            //   const element = item[ranges][i]
            //   if (tempValue === '') { // 初始化
            //     tempValue = element[0]
            //     tObj.value = element[0]
            //     tObj.list = [element[1]]
            //     if (item[ranges].length === 1) {
            //       objList.push(tObj)
            //     }
            //   } else {
            //     if (element[0] === tempValue) { // 同一级
            //       tObj.list.push(element[1])
            //       if (i === item[ranges].length - 1) {
            //         objList.push(tObj)
            //       }
            //     } else {
            //       objList.push(tObj)
            //       tObj = {}
            //       tempValue = element[0]
            //       tObj.value = element[0]
            //       tObj.list = [element[1]]
            //       if (i === item[ranges].length - 1) {
            //         objList.push(tObj)
            //       }
            //     }
            //   }
            // }
            // for (let j = 0; j < item[ranges].length; j++) {
            //   const element = item[ranges][j]
            //   tempObj.leftCode.push(element[0])
            //   tempObj.rightCode.push(tempArr)
            // }
            // tempObj.leftCode = this.unique(tempObj.leftCode) // 应用范围
            emptyList[0] = 1
            // for (let index = 0; index < objList.length; index++) {
            //   const element = objList[index]
            //   tempObj.leftCode.push(...element.value)
            //   tempObj.rightCode.push(...element.list)
            // }
          } else {
            emptyList[0] = 0
          }
          tempObj.leftCode = this.unique(tempObj.leftCode) // 应用范围
        } else {
          emptyList[0] = 0
          fullNum = 2
          if (item.measureName === '上月租售比') {
            fullNum = 4
          }
        }
        // 2.触发条件
        if (item.condition === '') {
          emptyList[1] = 0
        } else {
          tempObj.condition = item.condition
          emptyList[1] = 1
        }
        if (item.warningValue === '' || item.warningValue === null || item.warningValue === undefined) {
          emptyList[2] = 0
        } else {
          tempObj.warningValue = item.warningValue
          emptyList[2] = 1
        }
        if (item.measureName === '上月租售比') {
          if (item.orCondition === '') {
            emptyList[3] = 0
          } else {
            tempObj.orCondition = item.orCondition
            emptyList[3] = 1
          }
          if (item.orWarningValue === '' || item.orWarningValue === null || item.orWarningValue === undefined) {
            emptyList[4] = 0
          } else {
            tempObj.orWarningValue = item.orWarningValue
            emptyList[4] = 1
          }
        }
        if (this.sumNum(emptyList) !== 0 && this.sumNum(emptyList) !== fullNum) {
          let emptyIndex = 0
          let i = 0
          if (this.settingRadio === 0) {
            i = 1
          }
          for (let index = i; index < emptyList.length; index++) {
            const element = emptyList[index]
            if (element === 0) {
              emptyIndex = index
              break
            }
          }
          console.log('emptyList', emptyList)
          console.log('item.warningValue', item.warningValue)
          this.$message({
            type: 'warning',
            message: item.measureName + '中的' + areaList[emptyIndex] + '尚未填写，请填写完毕或全部留空'
          })
          this.btnDisabled = 2
          return false
        } else if (this.sumNum(emptyList) === 0) {
          if (item.status === 1) {
            this.$message({
              type: 'warning',
              message: item.measureName + '未填写规则，请关闭启用'
            })
            this.btnDisabled = 2
            return false
          }
        } else {
          params.detailRule.push(tempObj)
        }
        // 3. 是否启用
      }
      console.log('params.detailRule', params.detailRule)
      this.$confirm('保存后，系统将在次日根据最新规则进行预警。仅支持同时生效一份预警规则，是否保存并覆盖？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        customClass: 'message-box'
      }).then(() => {
        this.isChange = false
        this.axios.post(api.addOrUpdateWarningRule, params).then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.showSettingPanel(0)
            this.handleData()
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }).catch(() => {
        this.btnDisabled = 2
      })
    },
    addItem (index, item) {
      const newItem = {}
      Object.assign(newItem, item)
      newItem.isAdd = 1
      newItem.ranges = []
      newItem.ranges1 = []
      newItem.ranges2 = []
      newItem.warningValue = ''
      newItem.orWarningValue = ''
      newItem.condition = ''
      newItem.orCondition = ''
      if (this.tabsIndex === 0) {
        this.earlywarningManageList.splice(index + 1, 0, newItem)
      } else {
        this.earlywarningCostList.splice(index + 1, 0, newItem)
      }
    },
    delItem (index, item) {
      if (this.tabsIndex === 0) {
        this.earlywarningManageList.splice(index, 1)
      } else {
        this.earlywarningCostList.splice(index, 1)
      }
      this.changeArea(item)
    },
    handleSwitch (index) {
      this.isChange = true
      if (this.tabsIndex === 0) { // 高德的后端很麻烦，这个字段要是false和true就没那么麻烦了
        if (this.earlywarningManageList[index].status === 1) {
          this.earlywarningManageList[index].status = 2
        } else {
          this.earlywarningManageList[index].status = 1
        }
      } else {
        if (this.earlywarningCostList[index].status === 1) {
          this.earlywarningCostList[index].status = 2
        } else {
          this.earlywarningCostList[index].status = 1
        }
      }
    },
    handleTabs (key) {
      this.tabsIndex = key
      this.checkedArea = {}
      if (key) {
        this.settingRadio = this.settingRadioCost
      } else {
        this.settingRadio = this.settingRadioManage
      }
      if (this.settingRadio === 1) { // 高德唯斯的接口到上线前才暴露他们初始的设置错误，这里需要反转来处理
        this.settingRadio = 2
      } else if (this.settingRadio === 2) {
        this.settingRadio = 1
      }
    },
    getDataCountList (key) {
      this.optionManage = {}
      this.optionCost = {}
      this.checkedArea = {}
      const params = {
        projectId: this.filtro.project,
        measureType: this.tabsIndex + 1
      }
      if (key !== undefined) {
        params.measureType = key + 1
      }
      // getMeasureList查询当前所有的统计指标
      this.axios.get(api.getDpWarningRules, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.btnDisabled++
          const resData = res.data.data
          let lastName = ''
          if (key === 0) { // 经营
            if (resData) {
              this.settingRadioManage = resData.appliedRange ? resData.appliedRange - 1 : 0
            }
            this.earlywarningManageList = resData.warningRuleList.map((item, index) => {
              if (this.settingRadioManage === 2) {
                item.ranges1 = item.ranges
                item.ranges2 = []
              } else if (this.settingRadioManage === 1) {
                item.ranges1 = []
                item.ranges2 = item.ranges
              } else {
                item.ranges1 = []
                item.ranges2 = []
              }
              if (!(item.measureCode in this.optionManage)) {
                this.optionManage[item.measureCode] = item.industryFloorApplyRanges
              } else {
                console.log('?')
              }
              if (item.status === null) {
                item.status = 2
              }
              if (lastName === '') {
                lastName = item.measureName
              } else {
                if (item.measureName === lastName) {
                  item.isAdd = 1
                } else {
                  item.isAdd = 0
                  lastName = item.measureName
                }
              }
              return item
            })
          } else if (key === 1) {
            if (resData) {
              this.settingRadioCost = resData.appliedRange ? resData.appliedRange - 1 : 0
            }
            this.earlywarningCostList = resData.warningRuleList.map((item, index) => {
              if (this.settingRadioCost === 2) {
                item.ranges1 = item.ranges
                item.ranges2 = []
              } else if (this.settingRadioCost === 1) {
                item.ranges1 = []
                item.ranges2 = item.ranges
              } else {
                item.ranges1 = []
                item.ranges2 = []
              }
              if (!(item.measureCode in this.optionCost)) {
                this.optionCost[item.measureCode] = item.floorIndustryApplyRanges
              } else {
                console.log('?')
              }
              if (item.status === null) {
                item.status = 2
              }
              if (lastName === '') {
                lastName = item.measureName
              } else {
                if (item.measureName === lastName) {
                  item.isAdd = 1
                } else {
                  item.isAdd = 0
                  lastName = item.measureName
                }
              }
              return item
            })
            console.log('earlywarningCostList', this.earlywarningCostList)
          }
          if (this.settingShow) {
            if (this.tabsIndex === 0) {
              this.settingRadio = this.settingRadioManage
            } else {
              this.settingRadio = this.settingRadioCost
            }
            if (this.settingRadio === 1) { // 高德唯斯的接口到上线前才暴露他们初始的设置错误，这里需要反转来处理
              this.settingRadio = 2
            } else if (this.settingRadio === 2) {
              this.settingRadio = 1
            }
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataViewData () {
      const params = {
        projectId: this.filtro.project,
        buildingCode: this.buildingCode,
        floorCode: this.floorCode
      }
      this.axios.get(api.getStoreWarningViewData, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.isSetDataView = false
          const resData = res.data.data
          this.projectData = resData.warningPageInfoVo
          this.monitoringList = resData.warningOverviewVoList.map(item => {
            return item
          })
          this.monitoringStoreList = resData.attentionStoreWarningList.map(item => {
            return item
          })
          let flag = 0
          this.bubbleList = resData.warningStoreVoList.filter((item, index) => {
            if (flag === 3) {
              flag = 0
            }
            item.top = 0
            item.left = 0
            item.type = flag
            if (item.warningNum < 4) {
              item.size = 0
            } else if (item.warningNum > 3 && item.warningNum < 7) {
              item.size = 1
            } else if (item.warningNum > 6) {
              item.size = 2
            }
            item.textList0 = item.warningStoreDetailVoList.filter(ele => {
              return ele.measureTypeName === '经营监控'
            })
            item.textList1 = item.warningStoreDetailVoList.filter(ele => {
              return ele.measureTypeName === '费用监控'
            })
            flag++
            return item
          })
          // this.bubbleList.push(...this.bubbleList)
          // this.bubbleList.push(...this.bubbleList)
          // this.bubbleList.push(...this.bubbleList)
          // this.bubbleList.push(...this.bubbleList)
          // this.bubbleList.push(...this.bubbleList)
          // this.bubbleList.push(...this.bubbleList)
          // this.bubbleList = this.bubbleList.filter((item, index) => {
          //   return index < 100
          // })
          // this.bubbleList.push(...this.bubbleList)
          console.log('this.', this.bubbleList)
          const chartObj = new Chart({ })
          // this.$nextTick(() => {
          //   const ele = this.$refs.bubbleList
          //   const positionList = [
          //     [
          //       [0, -96 * 2],
          //       [0, +96 * 2],
          //       [-96 * 2, -96],
          //       [-96 * 2, +96],
          //       [+96 * 2, +96],
          //       [+96 * 2, -96]
          //     ],
          //     [
          //       [0, -96 * 4],
          //       [0, +96 * 4],
          //       [-96 * 4, 0],
          //       [+96 * 4, 0],
          //       [-96 * 2, -96 * 3],
          //       [-96 * 2, +96 * 3],
          //       [-96 * 4, -96 * 2],
          //       [-96 * 4, +96 * 2],
          //       [+96 * 2, +96 * 3],
          //       [+96 * 2, -96 * 3],
          //       [+96 * 4, +96 * 2],
          //       [+96 * 4, -96 * 2]
          //     ],
          //     [
          //       // [0, -96 * 6],
          //       // [0, +96 * 6],
          //       // [-96 * 6, -96 * 1],
          //       // [-96 * 6, +96 * 1],
          //       // [-96 * 6, -96 * 3],
          //       // [-96 * 6, -96 * 3],
          //       // [-96 * 6, +96 * 3],
          //       // [+96 * 6, -96 * 1],
          //       // [+96 * 6, +96 * 1],
          //       // [+96 * 6, -96 * 3],
          //       // [+96 * 6, +96 * 3],
          //       // [-96 * 2, -96 * 5],
          //       // [-96 * 2, +96 * 5],
          //       // [-96 * 4, -96 * 4],
          //       // [-96 * 4, +96 * 4],
          //       // [+96 * 2, +96 * 5],
          //       // [+96 * 2, -96 * 5],
          //       // [+96 * 4, +96 * 4],
          //       // [+96 * 4, -96 * 4]
          //     ]
          //   ]
          //   let flag = 1
          //   let times = 0
          //   for (let i = 0; i < ele.childNodes.length; i++) {
          //     const element = ele.childNodes[i]
          //     let topValue = 270
          //     let leftValue = -90
          //     if (i !== 0) {
          //       topValue += positionList[flag - 1][times][0]
          //       leftValue += positionList[flag - 1][times][1]
          //       times++
          //       if (times === 6 * flag) {
          //         times = 0
          //         flag++
          //       }
          //     }
          //     element.style.top = topValue + 'px'
          //     element.style.left = 'calc(50% + ' + leftValue + 'px' + ')'
          //   }
          // })
          if (this.bubbleList.length > 30) {
            this.$nextTick(() => {
              // const showHeight = document.documentElement.clientHeight // 限制区域高度
              // const showWidth = document.documentElement.clientWidth
              let showHeight = 2000
              let showWidth = 3000
              if (this.bubbleList.length < 51) {
                showHeight = 1200
                showWidth = 1800
              }
              const ele = this.$refs.bubbleList
              let count = this.bubbleList.length
              console.log('fff', count)
              const list = []
              let ranSum = 0
              while (count > 0) {
                const row = Math.floor(Math.random() * showHeight)
                const col = Math.floor(Math.random() * showWidth)
                if (list.length === 0) {
                  list.push({
                    left: col,
                    top: row
                  })
                  count -= 1
                } else {
                  const noOverlap = list.every(v => Math.hypot(v.left - col, v.top - row) > 192) // 这里的主要目的就是来保证每次随机生成的点确定的图片位置不会有重叠
                  if (noOverlap || ranSum > 1000) {
                    list.push({
                      left: col,
                      top: row
                    })
                    ranSum = 0
                    count -= 1
                  } else {
                    ranSum++
                  }
                }
              }
              for (let i = 0; i < ele.childNodes.length; i++) {
                const element = ele.childNodes[i]
                const item = list[i]
                element.style.top = item.top + 'px'
                element.style.left = item.left + 'px'
              }
            })
          }
          console.log('chartObj', chartObj)
          this.isSetDataView = true
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      // const canSetThat = (list, y, x) => {
      //   console.log('y', y)
      //   console.log('x', x)
      //   return list.every((v) => {
      //     console.log('v', v)
      //     console.log('Math.hypot(v.x - x, v.y - y)', Math.hypot(v.x - x, v.y - y))
      //     return Math.hypot(v.x - x, v.y - y) > 192
      //   })
      // }
    },
    handleData () {
      this.buildingCode = ''
      this.floorCode = ''
      this.limitNum = 0
      this.updateProjectIdFunc()
      this.searchStore() // 默认请求在营店铺接口
      this.getDataViewData()
      this.getDataGdBuilding()
      this.getDataGdFloors()
      this.getDataCountList(0)
      this.getDataCountList(1)
    },
    changeProject () {
      this.inputStore = ''
      this.buildingCode = ''
      this.floorCode = ''
      this.limitNum = 0
      this.storeList.splice(0)
      this.updateProjectIdFunc()
      this.searchStore() // 默认请求在营店铺接口
      this.getDataViewData()
      this.getDataGdBuilding()
      this.getDataGdFloors()
      this.btnDisabled = 0
      this.getDataCountList(0)
      this.getDataCountList(1)
    },
    changeFloor (code) {
      this.floorCode = code
      this.$refs.dragBox.style.transform = 'translate(0, 0)'
      this.$refs.scaleBox.style.transform = 'scale(1)'
      this.bubbleList.splice(0)
      this.getDataViewData()
    },
    getDataGdBuilding () {
      const params = {
        buildingCode: '',
        projectId: this.filtro.project
      }
      this.axios.post(api.getGdbuilding, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          this.buildingList = resData
          this.buildingList.unshift({
            buildingCode: '',
            buildingName: '全部楼宇'
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleFloorLimit (key) {
      if (key) {
        if (this.limitNum !== this.floorList.length - 5) {
          this.limitNum++
        }
      } else {
        if (this.limitNum !== 0) {
          this.limitNum--
        }
      }
      this.floorListLimit = this.floorList.filter((item, index) => {
        return index >= this.limitNum && index < this.limitNum + 5
      })
    },
    getDataGdFloors () {
      this.$refs.dragBox.style.transform = 'translate(0, 0)'
      this.$refs.scaleBox.style.transform = 'scale(1)'
      this.bubbleList.splice(0)
      const params = {
        buildingCode: this.buildingCode,
        projectId: this.filtro.project
      }
      this.axios.post(api.getGdFloor, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (this.buildingCode !== '') {
            this.floorList = resData
            this.floorList.unshift({
              buildingName: '全部',
              buildingCode: '' // 这个其实是floorCode，后端是这么说的
            })
            // this.floorList.push({
            //   buildingName: '111',
            //   buildingCode: '11'
            // })
            // this.floorList.push({
            //   buildingName: '222',
            //   buildingCode: '22'
            // })
            this.floorListLimit = this.floorList.filter((item, index) => {
              return index >= this.limitNum && index < this.limitNum + 5
            })
          }
          let marginLeftLen = 0
          if (this.buildingCode !== '') {
            switch (this.floorList.length) {
              case 2:
                marginLeftLen = 109
                break
              case 3:
                marginLeftLen = 133
                break
              case 4:
                marginLeftLen = 162
                break
              case 5:
                marginLeftLen = 185
                break
              default:
                marginLeftLen = 236
                break
            }
            this.$refs.floorsHandler.style.marginLeft = `calc(50% - ${marginLeftLen}px)`
            this.$refs.floorList.style.left = 0
          } else {
            this.$refs.floorsHandler.style.marginLeft = 'calc(50% - 52px)'
            this.$refs.floorList.style.left = '-500px'
          }
          this.floorCode = ''
          this.limitNum = 0
          this.getDataViewData()
          console.log('resData', resData)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataProjList () { // select项目列表
      this.loading = true
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          // if (this.projectOptions.length > 0) {
          //   this.filtro.project = this.projectOptions[0].id // 默认选中第一个项目
          //   this.projectInfo = this.projectOptions[0]
          // }
          this.getProjectIdFunc().then((res) => {
            // this.filtro.project = res.data.data.projectId
            this.$set(this.filtro, 'project', res.data.data.projectId)
            console.log('res.data.data.projectId', res.data.data.projectId)
            console.log('this.filtro.project', this.filtro.project)
            this.projectInfo = this.projectOptions.find(item => {
              return item.id === this.filtro.project
            })
            this.handleData()
            this.searchStore() // 默认请求在营店铺接口
          })
        }
        this.loading = false
      }, rej => {
        console.log(rej)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    showSettingPanel (key) {
      if (this.btnDisabled !== 2) {
        return false
      }
      const handlePanel = () => {
        this.settingShow = !this.settingShow
        if (this.settingShow) {
          this.$refs.settingPanel.style.left = '53px'
          this.$refs.settingBtn.style.left = '-47px'
        } else {
          this.$refs.settingPanel.style.left = '100%'
          this.$refs.settingBtn.style.left = '-59px'
        }
      }
      if (!key) { // 关闭面板判断
        if (this.isChange) {
          this.$confirm('系统检测到规则发生变化，是否进行保存？（保存将直接覆盖原有规则）', '提示', {
            confirmButtonText: '保存',
            cancelButtonText: '忽略',
            showClose: false,
            customClass: 'message-box'
          }).then(() => {
            this.submitSetting()
            return false
          }).catch(() => {
            this.isChange = false
            handlePanel()
            this.btnDisabled = 0
            this.getDataCountList(0)
            this.getDataCountList(1)
          })
        } else {
          this.btnDisabled = 0
          this.getDataCountList(0)
          this.getDataCountList(1)
          handlePanel()
        }
      } else { // 打开面板
        this.tabsIndex = 0
        this.settingRadio = this.settingRadioManage
        if (this.settingRadio === 1) { // 高德唯斯的接口到上线前才暴露他们初始的设置错误，这里需要反转来处理
          this.settingRadio = 2
        } else if (this.settingRadio === 2) {
          this.settingRadio = 1
        }
        handlePanel()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.store-earlywarning
  min-height 850px
  padding 10px 0 0 0
  color #fff
  font-size 14px
  background-color #000
  // #drag-box
    // border 1px solid #f00
  // #scale-box
    // border 1px solid #ff0
  //   height calc(100% - 40px)
  //   min-height 500px
  //   min-width 500px
  //   margin 20px
    // box-shadow 0 0 3px orange
  .w-crumbs-href
    margin-left 64px
    span,i
      color #808191
  .mt-14
    margin-top 14px
  .shadow-box
    background #272930
    border-radius 2px
    overflow hidden
  .data-list
    li
      width 45%
      display inline-block
      font-size 14px
      color #808191
      &:first-child
        margin-right 20px
      .data-span
        font-size 26px
        line-height 36px
        font-family Bahnschrift
        margin-right 5px
        color #fff
      .trend-span
        margin-left 4px
  .tbs-list
    margin 24px auto
    width 290px
    h3
      color #B2B3BD
      padding-bottom 15px
      border-bottom 1px solid #373a43
    span
      display inline-block
      width 50%
    ul
      height 148px
      overflow-y auto
      li
        color #808191
        border-bottom 1px solid #373A43
        padding 14px 0 15px 0
        position relative
        span.num
          color #fff
        &:last-child
          border none
        &:nth-child()
          border none
        .icon-page-enter
          position absolute
          right 10px
          top 17px
        &:hover
          .icon-page-enter,span
            color #F89407
      .low-item
        &:hover
          .icon-page-enter,span
            color #808191
  .wrap-box
    width 100%
    position relative
    .sidebar
      width 326px
      position absolute
      z-index 3
      margin-left 64px
      .project-select>>>
        background #373A43
        width 200px
        margin 0 auto
        border-radius 2px
        .el-input__inner
          box-sizing border-box
          width 200px
          height 36px
      .project-info
        margin-top 10px
        height 224px
        overflow hidden
        img
          width 106px
          margin 16px auto 14px auto
          border-radius 2px
          display block
          overflow hidden
        .data-list
          margin 30px 30px 0 30px
      .store-monitoring
        height 250px
    .main-panel
      margin-left 390px
      position relative
      overflow hidden
      .store-input>>>
        width 290px
        position absolute
        right 24px
        top 10px
        z-index 3
        background #2C2E38
        .el-input__inner
          width 290px
      .earlywarning-setting
        width calc(100% - 46px)
        height 750px
        margin-top 10px
        position absolute
        top 0px
        z-index 2
        transition .5s left ease-in-out
        left 100%
        .btn-setting
          width 50px
          height 50px
          text-align center
          position absolute
          padding-top 9px
          font-size 12px
          box-sizing border-box
          left -59px
          top 321px
          z-index 3
          background #373A43
          box-shadow 0px 3px 20px #000000
          border-radius 50%
          transition background .3 sease-in-out
          transition .5s left ease-in-out
          .icon-page-enter,.icon-page-return
            position absolute
            top 18px
            &:before
              color #fff
              font-size 14px
          .icon-page-return
            left 0px
        .pointer-evnets-auto
          &:hover
            background #f89407
            box-shadow 0px 3px 20px #f89407
            transition-property box-shadow,background
            transition-duration .3s,.1s
            transition-function ease-in-out,ease-in-out
        .btn-setting-back
          animation transparent-in 1s both
        .tabs-header
          li
            display inline-block
            background #272930
            height 60px
            line-height 60px
            font-size 18px
            padding 0 39px
            cursor pointer
            border-bottom 1px solid #373A43
            position relative
            color #808191
          li.active-item
            font-weight bold
            color #fff
            &:after
              content ''
              width 108px
              background #FFA134
              height 2px
              display inline-block
              position absolute
              bottom 0
              left 21px
        .tabs-body
          height 691px
          overflow hidden
          position relative
          background url('~@/assets/images/storeEarlywarning/set-panel-bg.png') 0 0 no-repeat
          .sub-tabs
            background-color #272930
            color #B2B3BD
            padding 33px 0 0 31px
            .radio-group
              margin-left 30px
          .earlywarning-list>>>
            .el-input__inner
              line-height 24px !important
            .el-cascader__tags .el-tag > span
              flex auto !important
          .earlywarning-list
            background-color #272930
            margin-left 20px
            height 595px
            overflow-y auto
            table
              margin 32px
              width calc(100% - 64px)
              th,td
                border-bottom 1px solid #373A43
                height 56px
                font-weight 500
              td
                color #808191
                .c-switch
                  margin-top 2px
                .more-margin
                  margin-top 10px
                  margin-bottom 10px
                .input-box>>>
                  display inline-block
                  background #373A43
                  border-radius 2px
                  height 24px
                  padding 0 15px
                  min-width 202px
                  box-sizing border-box
                  position relative
                  margin-left 16px
                  .unit
                    width 54px
                    display inline-block
                    text-align right
                  .el-cascader__tags .el-tag
                    margin 2px 0 2px 0
                  .el-input__inner
                    padding 0
                  .el-select
                    .el-input
                      width 100px
                  .el-cascader
                    line-height 24px
                    .el-input
                      min-width 202px !important
                  .value-item
                    input
                      text-indent 16px
                  .el-input
                    width 121px
                    line-height 24px
                    .el-input__suffix
                      position absolute
                      right 0
                      height 24px
                      .el-input__icon
                        line-height 24px
                    input
                      background #373A43
                      color #fff
                      height 24px !important
                      border none
                  .xl-select
                    .el-input__inner
                      width 60px
                    .el-input
                      width 80px !important
              th
                color #B2B3BD
                text-align left
          .submit-panel
            text-align center
            position absolute
            bottom 0
            padding-bottom 10px
            background #272930
            margin 0 auto
            width 100%
            span
              margin 20px 14px
  .mr-16
    margin-right 16px
  .bg-light
    background url('~@/assets/images/storeEarlywarning/light-blue.png') no-repeat center top
    top 0
    width 1111px
    height 900px
    opacity .3
    position absolute
    left 50%
    transform-origin 0 30%
    transform scale(.7,.7) translateX(-50%)
    z-index 0
  .bg-light-move-left,
  .bg-light-move-right,
  .bg-light-move-tright,
  .bg-light-move-tleft
    width 1111px
    height 700px
    margin 0 auto
    background url('~@/assets/images/storeEarlywarning/light-blue.png') no-repeat center top
    position absolute
    left 50%
    transform-origin 0 50%
    transform scale(.3,.3) translateX(-50%)
    z-index 1
    opacity 0
  .bg-light-move-right
    animation lightmoveright infinite 10s ease-in-out
  .bg-light-move-left
    animation lightmoveleft infinite 10s ease-in-out
  .bg-light-move-tright
    animation lightmovetright infinite 10s ease-in-out
    animation-delay 3s
  .bg-light-move-tleft
    animation lightmovetleft infinite 10s ease-in-out
    animation-delay 6s
  .building-floors-handler>>>
    overflow hidden
    position absolute
    bottom 138px
    margin-left calc(50% - 52px)
    z-index 2
    transition all ease-in-out .3s
    .el-input__inner
      width 104px
      height 44px
      background #32343B
      opacity 0.8
      border-radius 2px
      color #fff
    .input-solid
      z-index 2
    .floor-list
      float right
      margin-left 12px
      position relative
      z-index 1
      left -500px
      transition left ease-in-out .6s
      li
        transition all .3s
        user-select none
        color #808191
        display inline-block
        width 50px
        height 36px
        line-height 36px
        text-align center
        background #32343B
        opacity 0.8
        margin-right 1px
        &:first-child
          border-radius 2px 0 0 2px
        &:last-child
          border-radius 0 2px 2px 0
      .pointer
        &:hover
          color #fff
        i:hover
          color #fff
      .disabled-item
        opacity .5
      .active-item
        background #FFA134
        color #fff
  .bubble-wrap
    height 900px
    position relative
    transition .7s all ease-in-out
    background url('~@/assets/images/storeEarlywarning/bg.png')
    z-index 2
    .effects-light-list
      width 100%
      position absolute
      left 0
      bottom -50px
      display flex
      justify-content space-around
      li
        width 17px
        height 82px
        background url('~@/assets/images/storeEarlywarning/light.png')
        position relative
      li
        animation moveitup infinite 1.9s ease-out backwards
      li:first-child
        transform scale(.5, 1)
        animation-delay 1s
      li:nth-child(2)
        margin 0 50px 80px 30px
        animation-delay 1.3s
      li:nth-child(3)
        animation-delay .3s
      li:nth-child(4)
        animation-delay .7s
        transform scale(.3, 1)
      li:nth-child(5)
        animation-delay 1s
      li:nth-child(6)
        animation-delay 1.3s
      li:nth-child(7)
        animation-delay .8s
    .bubble-list
      height 850px
      position relative
      z-index 3
      .bubble-item
        text-align center
        margin 15px
        display inline-block
        position absolute
        z-index 1
        cursor default
        transition .7s all ease-in-out
        user-select none
        &:first-child
          left calc(50% - 90px)
          top 270px
        &:nth-child(2)
          left calc(50% - 270px)
          top 200px
        &:nth-child(3)
          left calc(50% - 180px)
          top 385px
        &:nth-child(4)
          left calc(50%)
          top 135px
        &:nth-child(5)
          left calc(50% + 50px)
          top 355px
        &:nth-child(6)
          left calc(50% + 130px)
          top 235px
        &:nth-child(7)
          left calc(50% - 180px)
          top 85px
        &:nth-child(8)
          left calc(50% + 150px)
          top 85px
        &:nth-child(9)
          left calc(50% - 50px)
          top 515px
        &:nth-child(10)
          left calc(50% - 350px)
          top 360px
        &:nth-child(11)
          left calc(50% + 250px)
          top 395px
        &:nth-child(12)
          left calc(50% + 150px)
          top 495px
        &:nth-child(13)
          left calc(50% - 390px)
          top 115px
        &:nth-child(14)
          left calc(50% - 300px)
          top 515px
        &:nth-child(15)
          left calc(50% + 300px)
          top 215px
        &:nth-child(16)
          left calc(50% - 470px)
          top 455px
        &:nth-child(17)
          left calc(50% - 470px)
          top 240px
        &:nth-child(18)
          left calc(50% - 300px)
          top 0
        &:nth-child(19)
          left calc(50% - 50px)
          top -10px
        &:nth-child(20)
          left calc(50% - 490px)
          top 0
        &:nth-child(21)
          left calc(50% - 580px)
          top 100px
        &:nth-child(22)
          left calc(50% - 630px)
          top 350px
        &:nth-child(23)
          left calc(50% - 650px)
          top 210px
        &:nth-child(24)
          left calc(50% - 600px)
          top 510px
        &:nth-child(25)
          left calc(50% - 450px)
          top 610px
        &:nth-child(26)
          left calc(50% + 350px)
          top 610px
        &:nth-child(27)
          left calc(50% + 70px)
          top 620px
        &:nth-child(28)
          left calc(50% - 180px)
          top 620px
        &:nth-child(29)
          left calc(50% + 280px)
          top 0
        &:nth-child(30)
          left calc(50% + 350px)
          top 111px
        &:hover
          z-index 999
          .text-panel
            transform scale(1,1)
        .text-panel
          position absolute
          z-index 999
          left 80%
          top 50%
          transform scale(0,0)
          width 318px
          padding-bottom 10px
          line-height 25px
          background-color rgba(0,0,0,.9)
          border-radius 4px
          border 1px solid rgba(128,129,145,.15)
          box-shadow 0px 6px 30px rgba(0, 0, 0, 0.86)
          font-size 12px
          text-align left
          transition transform ease-in-out .3s
          h3
            border-radius 4px 4px 0 0
            width 100%
            height 33px
            line-height 33px
            text-indent 8px
            background #272930
            color #B2B3BD
          h4
            margin 8px 8px 4px 8px
            color #B2B3BD
            line-height 12px
          li
            padding 0 8px
            font-weight 400
            white-space nowrap
      .float-type-0
        animation appear-in 1 ease-in-out .8s backwards,slide-in-0 infinite ease-in-out alternate 3.2s
      .float-type-1
        animation appear-in 1 ease-in-out .8s backwards,slide-in-1 infinite ease-in-out alternate 3.2s
      .float-type-2
        animation appear-in 1 ease-in-out .8s backwards,slide-in-2 infinite ease-in-out alternate 3.2s
      .size-l
        width 158px
        height 158px
        line-height 158px
        border-radius 158px
        background url('~@/assets/images/storeEarlywarning/ball-l.png')
        background-size 158px 158px
        font-size 20px
        span.ellipsis
          display inline-block
          width 95px
      .size-m
        width 118px
        height 118px
        line-height 118px
        border-radius 118px
        background url('~@/assets/images/storeEarlywarning/ball-m.png')
        background-size 118px 118px
        font-size 16px
        span.ellipsis
          display inline-block
          width 70px
      .size-s
        width 88px
        height 88px
        line-height 88px
        border-radius 88px
        background url('~@/assets/images/storeEarlywarning/ball-s.png')
        background-size 88px 88px
        font-size 14px
        span.ellipsis
          display inline-block
          transform scale(0.83, 0.83)
          width 52px
    @keyframes slide-in-0 {
      0% {
        margin-top 0px
      }
      40% {
        margin-top 10px
      }
      to {
        margin-top 0px
      }
    }
    @keyframes slide-in-1 {
      0% {
        margin-top 0px
      }
      60% {
        margin-top 15px
      }
      to {
        margin-top 0px
      }
    }
    @keyframes slide-in-2 {
      0% {
        margin-top 0px
      }
      50% {
        margin-top 15px
      }
      to {
        margin-top 0px
      }
    }
    @keyframes appear-in {
      0% {
        transform scale(0, 0)
      }
      100% {
        transform scale(1, 1)
      }
    }
  @keyframes transparent-in {
    0% {
      opacity 0
    }
    100% {
      opacity 1
    }
  }
  @keyframes moveitup {
    0% {
      opacity 0
      top 0
    }
    70% {
      opacity 1
    }
    85% {
      opacity .5
    }
    100% {
      opacity 0
      top -500px
    }
  }
  @keyframes lightmoveleft {
    0% {
      opacity 1
    }
    20% {
      left -700px
      opacity 0
    }
    100% {
      left -700px
      opacity 0
    }
  }
  @keyframes lightmoveright {
    0% {
      opacity 1
    }
    20% {
      left 2200px
      opacity 0
    }
    100% {
      left 2200px
      opacity 0
    }
  }
  @keyframes lightmovetleft {
    0% {
      top 0
      opacity 1
    }
    20% {
      top -500px
      left -700px
      opacity 0
    }
    100% {
      top -500px
      left -700px
      opacity 0
    }
  }
  @keyframes lightmovetright {
    0% {
      top 0
      opacity 1
    }
    20% {
      top -500px
      left 2200px
      opacity 0
    }
    100% {
      top -500px
      left 2200px
      opacity 0
    }
  }
@media screen and (max-width: 1780px)
  .input-box
    margin-left 16px
    margin-right 0 !important
</style>
